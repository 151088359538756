.Home .lander {
    padding: 80px 0;
    text-align: center;
}
  
.Home .lander h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
}
  
.Home .lander p {
    color: #999;
}

.Home .docs h4 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    overflow: hidden;
    line-height: 1.5;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.Home .docs p {
    color: #666;
}

.pull-rightxxx  {
    float: right;
    height: .5;
}


.glyphicon {
    font-size: 16px;
}


.tmp {
    padding: 2px;
}